<template>
  <div id="newStepOk">
    <div class="row">
      <div class="col-12">
        <div class="row justify-content-center align-items-center bg">
          <h4 class="font-weight-bold text-center text-white">Préstamo preaprobado de {{ max_prestamo }}€</h4>
        </div>
        <div class="row justify-content-center">
          <img width="115" height="115" src="@/assets/img/ok.svg" alt="circle_check_green" class="mt-3" />
        </div>
        <div class="row justify-content-center mt-3">
          <div class="col-10">
            <p class="text-blue text-center font-weight-bold">Muchas gracias</p>
            <p class="text-blue text-center font-weight-bold">La documentación se ha recibido correctamente.</p>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="container">
            <div class="col-12">
              <p class="text-blue text-center font-weight-bold">Un agente se pondrá en contacto para su validación.</p>
            </div>
          </div>
        </div>
        <div class="row justify-content-center pt-5 pb-3">
          <div class="container">
            <div class="col-12">
              <p class="text-blue text-center font-weight-bold">Si deseas agilizar tu solicitud, contáctenos</p>
            </div>
          </div>
        </div>
        <div class="row justify-content-center text-center">
          <div class="col-12 col-sm-9 col-md-9 col-lg-6 col-xl-4">
            <div class="card-columns pt-0 pl-4 pr-4 pb-4">
              <div class="card" @click="openWhatsapp()">
                <div class="row align-items-center">
                  <div class="col-3 pt-3 pb-3">
                    <img src="../../assets/img/whatsapp.svg" alt="dinero" />
                  </div>
                  <div class="col-9 text-left">
                    <span class="font-weight-bold">Enviar documentación por WhatsApp</span>
                  </div>
                </div>
              </div>
              <div class="card">
                <a href="tel:952864645">
                  <div class="row align-items-center">
                    <div class="col-3 pt-3 pb-3">
                      <img src="../../assets/img/phone.svg" alt="phone"  />
                    </div>
                    <div class="col-9 text-left">
                      <span class="font-weight-bold">Llamar por teléfono</span>

                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <section>
      <div class="row justify-content-around">
        <div class="col-10 col-sm-10 col-md-8 col-lg-6 col-xl-4">
          <p class="text-left"><a class="volver_circle" href="#" @click="back()">&lt;</a></p>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import store from "@/store";
import { mapState } from "vuex";

export default {
  name: "NewStepConfirm",
  data: function () {
    return {
      editar: false,
      telefono: "",
      email: "",
      checkTelefono: false,
      checkEmail: false,
    };
  },
  props: {
    solicitud: Object,
  },
  methods: {
    setContacto: function () {
      this.telefono = this.solicitud ? this.solicitud.def_telefono1 : null;
      this.email = this.solicitud ? this.solicitud.def_email1 : null;
    },
    validatePhone(phone) {
      let pattern = /^\d{9}$/;
      if (pattern.test(phone)) {
        return true;
      }
      return false;
    },
    validateEmail(mail) {
      var pattern = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      if (pattern.test(mail)) {
        return true;
      } else if (mail == "") {
        return false;
      }
      return false;

    },

    openWhatsapp: function () {
      window.open("https://api.whatsapp.com/send?l=es&phone=34913247400&text=%F0%9F%91%8B%20%C2%A1Hola!%20Prefiero%20continuar%20mi%20solicitud%20de%20pr%C3%A9stamo%20por%20WhatsApp.%20Muchas%20gracias.", "_blank");
    },
    back() {
      store.commit("setStep", 6);
      this.$router.go(-1);
    },
  },
  watch: {
    telefono: function () {
      this.checkTelefono = this.validatePhone(this.telefono);
    },
    email: function () {
      this.checkEmail = this.validateEmail(this.email);
    },
  },
  store,
  computed: mapState({
    step: (state) => state.formulario.step,
    api_token: (state) => state.api_token,
    formulario: (state) => state.formulario,
    data_matricula: (state) => state.formulario.data_matricula,
    solicitud: (state) => state.solicitud,
    max_prestamo: (state) => state.max_prestamo,
  }),
  mounted: function () {
    this.setContacto();
  },
};
</script>

<style scoped lang="scss">
// Resposive para pantallas a partir de tamaño medio a pequeño

@import "bootstrap/scss/_functions.scss";
@import "bootstrap/scss/_variables.scss";
@import "bootstrap/scss/_mixins.scss";

#newStepOk {
  .card {
    background-color: white;
    border: 2px solid rgba(196, 196, 196, 0.4);
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
  }
  .text-blue {
    color: #004680;
  }
  .volver_circle {
    height: 35px;
    width: 35px;
    display: table-cell;
    text-align: center;
    vertical-align: middle;
    border: solid 1px #dadada;
    border-radius: 50%; /* may require vendor prefixes */
    background: #fff;
  }
}

@include media-breakpoint-only(xs) {
  .bg {
    height: 89px;
    padding: 1rem;
    margin-top: 75px !important;
    margin-bottom: 25px;
  }
  .card-columns {
    column-count: 1;
    max-width: 100%;
    width: 100%;
  }
  .card {
    width: 100%;
    height: auto;
  }
  .card:hover {
    width: 100%;
    height: auto;
  }
}

@include media-breakpoint-only(sm) {
  .bg {
    height: 89px;
    padding: 1rem;
    margin-top: 106px !important;
    margin-bottom: 25px;
  }
  .card-columns {
    column-count: 1;
    max-width: 100%;
    width: 100%;
  }
  .card {
    width: 100%;
    height: auto;
  }
  .card:hover {
    width: 100%;
    height: auto;
  }
}

@include media-breakpoint-only(md) {
  .bg {
    height: 89px;
    padding: 1rem;
    margin-top: 90px !important;
    margin-bottom: 25px;
  }
  .card-columns {
    column-count: 1;
    max-width: 100%;
    width: 100%;
  }
  .card {
    width: 100%;
    height: auto;
  }
  .card:hover {
    width: 100%;
    height: auto;
  }
}

@include media-breakpoint-only(lg) {
  .bg {
    height: 89px;
    padding: 1rem;
    margin-top: 95px !important;
    margin-bottom: 25px;
  }
  .card-columns {
    column-count: 1;
    max-width: 100%;
    width: 100%;
  }
  .card {
    width: 100%;
    height: auto;
    max-height: 78px;
  }
  .card:hover {
    width: 100%;
    height: auto;
    max-height: 78px;
  }
}

@include media-breakpoint-between(xl, xxl) {
  .bg {
    height: 89px;
    padding: 1rem;
    margin-top: 95px !important;
    margin-bottom: 25px;
  }
  .card-columns {
    column-count: 2;
    max-width: 100%;
    width: 100%;
  }
  .card {
    width: 100%;
    height: auto;
    max-height: 78px;
  }
  .card:hover {
    width: 100%;
    height: auto;
    max-height: 78px;
  }
}
</style>

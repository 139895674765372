<template>
  <div id="volver_row_form" class="row justify-content-around pt-4 pb-4">
    <div class="col-10 text-left" @click="back()" >
      <svg width="35" height="35" viewBox="0 0 35 35" @mouseenter="isHovered = true" @mouseleave="isHovered = false" fill='#004680' stroke='#004680'  xmlns="http://www.w3.org/2000/svg">
        <circle cx="17.5" cy="17.5" r="17"  stroke='#004680' :fill="isHovered ? '#004680' : 'white'" />
        <path d="M3 17L18 25.6603V8.33975L3 17ZM28 15.5L16.5 15.5V18.5H28V15.5Z" :fill="isHovered ? 'white' : '#004680'" :stroke="isHovered ? 'white' : '#004680'" />
      </svg>
    </div>
  </div>
</template>

<script setup>
import { useRouter } from "vue-router";
import { ref } from 'vue'



const router = useRouter();
const back = () => router.go(-1);

//Controlamos si el Mouse esta sobre el boton, para generar un efecto HOVER
const isHovered = ref(false);

</script>

<style lang="scss" scoped>
.volver_circle {
  height: 35px;
  width: 35px;
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  border: solid 1px #dadada;
  border-radius: 50%; /* may require vendor prefixes */
  background: #fff;
}
</style>
